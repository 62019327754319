.header {
  position: relative; // Ensure the relative positioning for the overlay

  .mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &.dark-overlay {
      background-color: rgba(255, 255, 255, 0.5); // Semi-transparent background
    }
    &.light-overlay {
      background-color: rgba(0, 0, 0, 0.5); // Semi-transparent background
    }
    z-index: 998; // Overlay should be below the menu
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 80%;
    max-width: 300px;
    height: 100%;
    background-color: #ffffff;
    box-shadow: -2px 0 4px rgba(0, 0, 0, 0.1);
    padding: 1rem 0;
    z-index: 999;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out; /* Initial transition */

    &.open {
      transform: translateX(0%); /* Opened state transition */
    }

    &.dark-mode {
      background-color: #2b2b2b;
    }

    .menu-items {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0 1rem;
  
      .menu-item {
        padding: 0.5rem 0.5rem;
        transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease; // Add color transition
        flex-grow: 1; // Distribute space evenly
  
        &:hover {
          background-color: #f0f3ff;
          color: #4a90e2; // Change text color on hover
        }
  
        &:not(:last-child) {
          border-bottom: 1px solid #e0e0e0; // Add border line between menu items
        }
  
        &.dark-mode {
          border-color: #4a4a4a; // Set border color for dark mode
        }
      }
    }
  
    .close-menu {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    .menu-items {
      display: none;
    }
  }
}
